@import "../../../styles/media-queries.scss";

.dashboardheader {
  @include lt-sm {
    > .ui-dropdown {
      width: 100%;

      > .ui-dropdown__container {
        width: 100%;
      }
    }

    > .ui-flex {
      width: 100%;
      justify-content: flex-end;
      -webkit-justify-content: flex-end;
      // white-space: nowrap;
    }
  }
}

.react-grid-item {
  overflow: hidden;
  > .ui-card {
    width: 100% !important;
  }

  button {
    z-index: 10;
  }
}

.dashCardForm {
  input {
    width: 100%;
  }
}

.editDashboardDialog {
  max-width: 400px;
  overflow-wrap: anywhere;

  > .ui-header {
    margin-bottom: 20px;
  }

  > div.ui-box {
    margin-bottom: 0;
  }
}

.dashboardsContainer {
  grid-template-columns: repeat(1, 100%);

  .ui-card.dashboardCard {
    .dashboardName {
      width: calc(100% - 260px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    @include lt-sm {
      padding: 7px 0;

      .dashboardName {
        width: calc(100% - 150px);
      }

      .k-dialog-wrapper button,
      .memberButton {
        min-width: 5rem;

        > .ui-box {
          margin-right: 0.6944rem;
        }

        > span.ui-button__content {
          display: block;
        }
      }

      button {
        min-width: auto;

        > .ui-box {
          margin-right: 0;
        }

        > span.ui-button__content {
          display: none;
        }
      }
    }

    width: 100%;
    box-shadow: none !important;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-radius: 0;
    padding: 7px 12px;

    &:hover:first-child {
      background-color: transparent !important;
    }

    .dashCardForm {
      flex-direction: row;
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
      align-items: center;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
    }

    > .ui-grid:first-child {
      padding-right: 12px;

      @include lt-sm {
        &.groups {
          grid-template-columns:
            minmax(150px, 1fr) minmax(100px, 1fr) minmax(70px, 1fr)
            minmax(80px, 1fr);
        }

        &.people {
          grid-template-columns:
            minmax(50px, 1fr) minmax(150px, 1fr) minmax(70px, 1fr)
            minmax(80px, 1fr) minmax(90px, 1fr) minmax(120px, 1fr) minmax(60px, 1fr);
        }

        &.cards {
          grid-template-columns: 23px minmax(100px, 1fr) 80px 40px 150px 75px;
          /*grid-template-columns: 23px repeat(auto-fill, minmax(54px, 1fr));*/
        }

        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        overflow-wrap: anywhere;
        padding-right: 0;

        button {
          min-width: auto;
        }
      }
    }
  }
}

body.default {
  .react-grid-placeholder {
    background-color: #6264a7 !important;
  }

  .dashboardsContainer,
  .addcardcontainer {
    .ui-card.dashboardCard,
    .categorylist {
      border-bottom-color: rgb(237, 235, 233);
    }

    .ui-card.dashboardCard.focused {
      background-color: #464775 !important;
      color: #fff;

      .btnFormAction svg {
        fill: #fff;
      }
    }

    .ui-card.dashboardCard:hover {
      .widget_visualization_container {
        color: #fff;
      }
    }
  }

  .widget_visualization_container {
    color: rgb(70, 71, 117);
  }

  .widget_target_container,
  .widget_category_container {
    > i {
      background: rgb(98, 100, 167);
      color: #fff;
    }
  }

  .dashboard-selectbox {
    > .ui-dropdown__container {
      box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
      border-color: rgb(225, 223, 221);
    }
  }
}
body.dark {
  .react-grid-placeholder {
    background-color: #c8c6c4 !important;
  }

  .dashboardsContainer,
  .addcardcontainer {
    .ui-card.dashboardCard,
    .categorylist {
      border-bottom-color: rgb(59, 58, 57);
    }

    > div:first-child {
      background-color: transparent !important;
    }
  }

  .dashboardsContainer,
  .addcardcontainer {
    > div:hover:first-child {
      color: #fff;
    }
  }

  .widget_visualization_container {
    color: #fff;
  }

  .widget_target_container,
  .widget_category_container {
    > i {
      background: rgb(98, 100, 167);
      color: rgb(32, 31, 31);
    }
  }

  .dashboard-selectbox {
    > .ui-dropdown__container {
      border-color: rgb(96, 94, 92);
    }
  }
}
body.contrast {
  .react-grid-placeholder {
    background-color: #dafcff !important;
  }

  .dashboardsContainer,
  .addcardcontainer {
    > div:hover:first-child {
      color: #fff;
    }
  }
  // .dashboardsContainer {
  //     > div.ui-card:not(:first-child):hover{
  //         .widget_visualization_container{
  //             color:rgb(0, 0, 0);
  //         }
  //     }
  // }
  .widget_visualization_container {
    color: rgb(255, 255, 1);
    // color: #fff;
  }

  .widget_target_container,
  .widget_category_container {
    > i {
      background: rgb(255, 255, 1);
      // background: #fff;
      color: rgb(0, 0, 0);
    }
  }
}

.addcardcontainer,
.editcardcontainer {
  @include lt-sm {
    flex-direction: column;

    > div:first-child {
      margin-bottom: 0.625rem;
      margin-right: 0;
    }

    > div:last-child > div {
      padding: 0;
    }

    .categorylist {
      // display: inline-flex;
    }

    .dashboardsContainer {
      overflow-x: auto;
      grid-template-columns: repeat(1, 100%);
    }
  }

  &:hover:first-child {
    background-color: transparent !important;
  }

  .widget_visualization_container {
    font-size: 24px;
    height: 26px;
    width: 24px;
    margin-top: -8px;
  }

  .widget_target_container,
  .widget_category_container {
    font-size: 12px;

    > i {
      border-radius: 50%;
      padding: 4px;
    }
  }
}

.ui-dialog.shareDialog {
  > div {
    text-align: center;
  }

  .ui-button {
    margin-top: 20px;
  }
}

.visualisation-span {
  width: 20px;

  i {
    transform: rotate(-90deg);
    font-size: 20px;
    display: block;
    height: 20px;
    width: 20px;
  }
}

// @media (max-width: 767px){
//     .addcardcontainer{
//         flex-direction: column;
//         > div:first-child{
//             margin-bottom: 0.625rem;
//         }
//         .categorylist{
//             display: inline-flex;
//         }
//     }
// }
.react-grid-item > .ui-card {
  > .ui-card__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    height: 44px;
  }
  > .ui-card__body {
    height: calc(100% - 45px);
  }
}

.dashboard-selectbox {
  > .ui-dropdown__container {
    border-width: 1px;
    border-style: solid;
  }
}

.demoModeArea {
  display: flex;
  align-items: center;
}

.demoMode {
  display: flex;
  align-items: center;
  text-align: right;
}
