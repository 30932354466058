.st_good,
.st_average,
.st_poor {
  > svg {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

.st_good {
  color: rgb(35, 123, 75);
}

.st_average {
  color: rgb(248, 210, 42);
}

.st_poor {
  color: rgb(196, 49, 75);
}

.activity-view {
  float: left;
  width: 100%;
  height: 100%;
}

.smrL-label {
  float: left;
  width: 100%;
  padding: 0 20px 0 20px;
}

.mainLabelRow {
  float: left;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 9px 0 9px 0;
}
.mainLabelRow:last-child {
  border: none;
}

.mainLabel {
  float: left;
  width: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.mainLabel-p {
  font-size: 14px;
  padding: 0 20px 0 0;
  min-width: 150px;
}
.mainLabel-val {
  font-size: 20px;
  font-weight: 600;
}
.mainLabel-val.lblValTxt {
  font-size: 18px;
}
