@import '../../../styles/media-queries.scss';

.edit_user_container{
    @include lt-sm{
        min-width: unset !important;
        width: 100%;
    }
}

.user-profile-header{
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-gap: 10px;
    @include lt-sm {
        grid-template-columns: 100% !important;
        grid-template-rows: auto 1fr;
    }
}

.no-data-icon{
    @include lt-sm{
        margin-top: 1em !important;
    }
}

.ui-provider {
    > .ui-tooltip__content{
        z-index: 999999;
    }
}


.profile-link{
    cursor:pointer;

    &:hover{
        text-decoration:underline;
    }
}
.user-profile {
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-gap: 10px;

    > .ui-segment {
        &.focused {
            border: 2px solid #ccc;
        }
    }

    @include lt-sm {
        grid-template-columns: 100% !important;
        grid-template-rows: auto 1fr;

        .user-profile-managerlisting {
            height: 100% !important;
            max-height: 330px;
        }

        .user-profile-groupslisting {
            height: 100% !important;
            max-height: 330px;
        }
    }
}
.user-info {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    align-items: flex-start;
    -webkit-box-align: flex-start;
    -moz-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;
}

.whRow {
    @include lt-sm{
        grid-template-columns: 100% !important;
    }

    .ui-dropdown__container {
        max-width: 100%;
    }
}
.timePicker,
.datePicker{
    > .ui-box{
        width: 100%;

        input.ui-box{
            width: 100%;
            padding: 0.3571rem 2.5rem 0.3571rem 0.8571rem;

            &::-webkit-calendar-picker-indicator {                
                font-size: 16px;
                cursor: pointer;
            }

            &:not(:required) {
                padding: 0.3571rem 0.8571rem;
            }
        }
    }
}

.editBtnGroup {
    display: flex;
    justify-content: flex-start;
    -webkit-justify-content:flex-start;
    @include lt-sm {
        > button.ui-button {
            min-width: auto;
            padding: 8px 12px;

            > span.ui-button__content {
                font-size: 0.8em;
            }
        }
    }
}


.textOverFlow{
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    width:100%;
    text-align: start;
}

.leftSideBar{
    min-width: 200px;
}
.rightSideBar{
    max-width: calc(100% - 200px);

    @include lt-sm{
        max-width: 100%;
    }
}

.permissions-group{
    > .ui-grid { 
        > *:first-child {
            text-align: left;
        }
        > *:last-child{
            text-align: left;
        }

        &:not(.whRow){
            grid-template-columns: 135px calc(100% - 143px) !important;
        }
    }

    @include lt-sm{
        .ui-dropdown__items-list {
            width: 100% !important;
            transform: translate(0, 33.3333px) !important;
        }
    }
}

.edit_user_officeHoursForm{
    @include lt-sm{
        > .ui-grid:not(.whRow){
            position: relative;
            padding-top: 24px;
            grid-template-columns: calc(50% - 16px) 16px calc(50% - 16px) !important;

            > span.ui-text:first-of-type {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .ui-dropdown__items-list {
            width: 100% !important;
            transform: translate(0, 33.3333px) !important;
        }
    }
}

.chkTrial-mobile{
    padding-right: 0 !important;
    grid-template-columns: 100% !important;
}