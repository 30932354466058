body.dark {
    .smrR {
        background: #605e5c;
    }

    .topTile {
        background: #484644;
    }
}

body.default {
    .smrR {
        background: rgba(0,0,0,.025);
    }

    .topTile {
        background: rgba(0,0,0,.025);
    }
}

body.contrast {
    .smrR {
        background: #17d4e6;
        color: #252423;
    }

    .topTile {
        background: #13b3c2;
    }

    .mainLabel-p svg {
        fill: rgba(255, 255, 1, 0.5)
    }

    .ui-card:hover {
        .mainLabelRow, .subLabelRow {
            border-color: rgba(0, 0, 0, 0.1);
        }
    }

    .mainLabelRow, .subLabelRow {
        border-color: rgba(255, 255, 1, 0.15);
    }
}



.smryDiv {
    float: left;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.smrL {
    float: left;
    width: 100%;
}

.smrL-label {
    float: left;
    width: 100%;
    padding: 0 20px 0 20px;
}

.mainLabelRow {
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 9px 0 9px 0;
}

.mainLabel {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mainLabel-p {
    font-size: 14px;
    padding: 0 20px 0 0;
    min-width: 150px;
    // color: rgba(0, 0, 0, 0.8);
}

.activity-view .root_02b12b2b {
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    columns: #444;
}

.mainLabel-val {
    font-size: 20px;
    font-weight: 600;
    // color: rgba(0, 0, 0, 0.8);
}

.mainLabel-p svg {
    height: 43%;
    vertical-align: top;
    margin-top: 2px;
    color: #888;
}

.subLabel {
    float: left;
    width: 100%;
    padding: 3px 0 0 0;
    margin: 0;
}

.subLabelRow {
    float: left;
    width: calc(100% - 36px);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 3px 0 2px 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mainLabel-p {
    font-size: 14px;
    padding: 0 20px 0 0;
    min-width: 150px;
    // color: rgba(0, 0, 0, 0.8);
}

.mainLabel-val {
    font-size: 20px;
    font-weight: 600;
    // color: rgba(0, 0, 0, 0.8);
}

.smrR {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0 0 0 10px;
    // background: rgba(98, 100, 167, 0.1);
}

.smrR-top {
    float: left;
    width: 100%;
    padding: 0;
    /* border-bottom: 1px solid rgba(0,0,0,.16); */
    display: flex;
    justify-content: space-between;
}

.topTile:first-child {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.14);
}

.topTile {
    // background: rgba(98, 100, 167, 0.8);
    float: left;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid rgba(0,0,0,.14);
    border-bottom: 1px solid rgba(0,0,0,.14);
    padding: 5px 0 5px 0;
    // color: #333;
}

.topTile-val {
    font-size: 20px;
    font-weight: 600;
}

.smrBott {
    float: left;
    width: 100%;
    padding: 0;
}

.smrR-latestAct {
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    padding: 10px 15px 10px 15px;
    display: flex;
    align-items: center;
}

.smrR-nextAct {
    float: left;
    width: 100%;
    padding: 6px 0 8px 15px;
}

.latestActTitle {
    padding: 0 10px 0 0;
    font-size: 14px;
}

.latestActData {
    display: flex;
    align-items: center;
    font-size: 11px;
}

.nextActTitle {
    font-size: 16px;
    font-weight: 600;
    padding: 0 0 1px 0;
}

.nextActData {
    float: left;
    width: 100%;
}

.nextActDataRow {
    float: left;
    width: 100%;
    padding: 5px 0 0 0;
    display: flex;
}

.nextActDataRow-p {
    min-width: 100px;
    float: left;
    font-size: 14px;
    font-weight: 400;
}

.nextActDataRow-h {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
}