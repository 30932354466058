.login-container-msg {
    width: 50%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.innerlogin-msg {
    margin-top: -15px;
    width: 100%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99;
    float: left;
    background-color: white;
    color: #333;
    padding: 25px;
    border-radius: 4px;
}

.notification-msg {
    text-align: center;
    float: left;
    width: 100%;
}

.notification-msg-i {
    float: left;
    width: 100%;
    text-align: center;
    padding: 20px 0 15px 0;
}

    .notification-msg-i i {
        font-size: 60px;
        color: #99b1d6;
    }

.notification-msg-h {
    font-size: 27px;
    padding: 0 0 5px 0;
}

.notification-msg-p {
    font-size: 18px;
    padding: 0 0 10px 0;
}

.notification-msg-btn {
    padding: 15px 0 20px 0;
}

    .notification-msg-btn .btn {
        padding: 8px 20px 8px 20px;
        font-size: 15px !important;
    }


.login-container-msg.datatrkMain {
    width: 80%;
}
.signUp-data {
    display: flex;
    justify-content: space-around;
    border: 1px solid #40587C;
}
.signUp-datatrk {
    width: 100%;
    padding: 10px 70px;
    flex-grow: 1;
    display: flex;
    align-items: center;
}
    .signUp-datatrk.datatrkL {
        background: #fff;
    }
    .signUp-datatrk.datatrkR {
        background: #40587C;
    }
    .signUp-datatrk.datatrkL .signUp-datatrkInr {
        text-align: center;
    }
.datatrk-logo {
    padding: 0 0 50px 0;
}
.datatrk-h {
    padding: 0 0 40px 0;
    font-size: 20px;
    font-family: 'Arial';
    font-weight: 600;
    color: #666;
}
.datatrk-p {
    font-size: 15px;
    font-family: 'Arial';
    line-height: 24px;
    color: #555;
    padding: 0 0 50px 0;
}
.signUp-datatrk.datatrkR .datatrk-h {
    color: #fff;
}
.signUp-datatrk.datatrkR .datatrk-p {
    color: #fff;
}
.datatrk-btn {
    cursor: pointer;
    border: 2px solid #fff;
    color: #fff;
    padding: 6px 15px 8px 15px;
    text-align: center;
    font-size: 20px;
    border-radius: 6px;
    font-family: 'Arial';
    text-decoration:none;
}
    body {
        font-family:Arial;
    }
.datatrk-btn:hover {
    border: 2px solid #fff;
    color: #fff;
}
    .datatrk-btn:focus {
        border: 2px solid #fff;
        color: #fff;
    }
.datatrk-btnOuter {
    text-align: right;
    padding: 30px 0 0 0;
}

@media (max-width: 690px) {
    .signUp-data {
        display: block;
    }

    .signUp-datatrk {
        display: block;
    }

    .login-container-msg.datatrkMain {
        width: 100%;
    }

    .innerlogin-msg {
        position: absolute;
        transform: initial;
        top: 0;
        left: 0;
        padding: 19px;
        width: 100%;
    }

    .datatrk-logo img {
        width: 100%;
    }
}