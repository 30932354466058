body.dark {
    
}

body.contrast {
    
}

.comparisonView {
    height: 100%;
    .compareGraph{    
        grid-template-columns: 120px calc(100% - 240px) 120px;
    }
}

.topUsrWT .topUsrAreaPic-tx {
    color: rgba(255, 255, 255, 0.75);
}

.topUsrWT .contact-list-icon {
    border: 2px solid rgba(255, 255, 255, 0.4);
}

.topUsrWT .contact-list-icon-txt {
    color: rgba(255, 255, 255, 0.8);
}

.topUsrWT .topUsrAreaDtl-t {
    color: rgba(255, 255, 255, 0.9);
}

.topUsrWT .topUsrAreaDtlLblRow-h {
    color: rgba(255, 255, 255, 0.75);
}

.topUsrWT .topUsrAreaDtlLblRow-dot {
    color: rgba(255, 255, 255, 0.75);
}

.topUsrWT .topUsrAreaDtlLblRow-val {
    color: rgba(255, 255, 255, 0.75);
}

.compareGraph .topUsrAreaDtlLblRow-h {
    min-width: 70px;
    max-width: 72%;
    line-height: 16px;
    padding: 0 5px 0 0;
}

.compareGraph .topUsrAreaDtlLblRow {
    padding: 6px 0 6px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.topUsrAreaDate {
    float: left;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 10px 0 0 0;
    padding: 10px 0 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}

.topUsrAreaDateTrk-p {
    font-size: 11px;
    opacity: 0.5;
}

.topUsrAreaDateTrk-val {
    font-size: 11px;
    opacity: 0.5;
}
