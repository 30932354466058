body.dark {
    .compareDataInr {
        background: #484644;
    }

    .compareDataInrRow {
        border-color: rgba(0, 0, 0, 0.14);
    }

    .compareDataInrRow-p {
        background: #605e5c;
    }

    .compareDataInrRow-val {
        background: #484644;
    }

    .k-window {
        border-color: rgba(0, 0, 0, 0.14);
        color: #fff;
        background-color: rgba(59, 58, 57, 1);
    }
}
body.contrast {
    .compareDataInr {
        background: #17d4e6;
        color: #252423;
    }

    .compareDataInrRow {
        border-color: rgba(25, 102, 110, 0.7);
    }

    .compareDataInrRow-p {
        background: #13b3c2;
    }

    .compareDataInrRow-val {
        background: #17d4e6;
    }
}

.userComparisonView {
    height: 100%;
    .compareGraph{    
        grid-template-columns: 90px calc(100% - 180px) 90px;
    }
}

.compareGraph {
    width: 100%;
    height: 100%;
    display: grid;
}

.compareTrk {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
}

.tmRowTitle .compareDataInrRow-p {
    font-weight: 700;
    text-transform: uppercase;
    padding: 9px 0 9px 0;
    // color: rgba(0,0,0,.99);
}

.compareDataInr {
    float: left;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.03);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.compareDataInrRow {
    float: left;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid rgba(98,100,167,.25);
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.compareDataInrRow-val {
    width: 25%;
    text-align: center;
    padding: 7px 0 7px 0;
    font-size: 0.85rem;
    font-weight: 700;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.compareDataInrRow-p {
    padding: 7px 0 7px 0;
    width: 100%;
    text-align: center;
    background: rgba(0,0,0,.02);
    font-size: 0.85rem;
    font-weight: 600;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.compareTrk .topUsrArea {
    border: none;
    background: rgba(0,0,0,.0);
    padding: 8px 15px 8px 15px;
    margin: 0;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

.compareTrk.compareTm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.chooseTeamData-btn {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.0);
    left: 0;
    top: 0;
    z-index: 1;
}

.compareGraph .topUsrAreaPic {
    width: 100%;
    justify-content: center;
}

.compareGraph .topUsrAreaPic .topUsrAreaPic-i {
    padding: 0;
}

.compareGraph .topUsrAreaDtl-t {
    text-align: center;
    padding: 0 0 15px 0;
    position: relative;
}

.chooseTeamOuter {
    position: absolute;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    left: 50%;
    right: 50%;
    top: 25px;
    z-index: 99;
}

.compareGraph .chooseTeam {
    transform: none !important;
    background: #fff;
    min-width: 100%;
    min-height: 180px;
    position: absolute;
    left: 0 !important;
    padding: 0;
    transform: translate3d(0,0,0);
    box-shadow: 0 2px 7px 2px rgba(138,155,165,.55);
    cursor: default;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 0 20px 0;
}

.chooseTeamTitle {
    background: rgba(98,100,167,.75);
    font-size: 0.85rem;
    padding: 7px 1px 8px 1px;
    color: rgba(255,255,255,.95);
}

.chooseTeamInr {
    padding: 8px 10px 12px 10px;
}