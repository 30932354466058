.meetingHabit {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.teamHabits .progressBoxRow {
    padding: 0 15px 0 0;
}

.progressBoxData {
    display: flex;
    justify-content: space-between;
}

.progressBoxRow {
    margin-bottom: 4px;
}
