.reaction-part-icn .root_02b12b2b {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  width: 100%;
  height: 100%;
  columns: #444;
}
.reaction-part {
  float: left;
  width: 100%;
  flex-wrap: wrap;
  /* min-height: 209px; */
  height: 100%;
  display: grid;
  align-items: center;
  grid-gap: 10px;
  justify-content: center;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: calc(50% - 5px) calc(50% - 5px);
}
.reaction-part-trk {
  height: 100%;
  padding: 10px;
  float: left;
  width: 100%;
  cursor: default;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-rows: 60% 40%;
}
.reaction-part-icn {
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  padding: 0;
  /* color: rgba(255,255,255,.99); */
  margin: 0 2px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reaction-part-val {
  font-size: 20px;
  font-weight: 600;
  /* color: #777; */
}

.static-reaction-like {
  background-position: 10% 100%;
  transform: scaleX(-1);
}

[class*="animated-reaction"] {
  height: 30px;
  width: 30px;
  max-width: 30px;
  max-height: 30px;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
}

[class*="animated-reaction"] .reaction {
  display: block;
}
[class*="animated-reaction"] .reaction:hover {
  animation-iteration-count: infinite !important;
}
[class*="animated-reaction"] img {
  float: left;
  position: static;
  padding-left: 30px;
}
@keyframes anim-keyframes {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

[class*="animated-reaction"] span {
  animation-play-state: paused;
}

.animated-reaction-like .reaction {
  background-image: url(https://statics.teams.cdn.office.net/evergreen-assets/skype/v2/yes/30a.png?v=4);
  animation: yes 1.6667s steps(40) 5;
  height: 1200px;
  background-size: 30px 1200px;
}
@keyframes yes {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-1200px);
  }
}
.static-reaction-sad {
  background-position: 60% 50%;
}
.animated-reaction-sad .reaction {
  background-image: url(https://statics.teams.cdn.office.net/evergreen-assets/skype/v2/sad/30a.png?v=4);
  animation: sad 2.625s steps(63) 5;
  height: 1890px;
  background-size: 30px 1890px;
}
@keyframes sad {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-1890px);
  }
}
.static-reaction-laugh {
  background-position: 10% 50%;
}
.animated-reaction-laugh .reaction {
  background-image: url(https://statics.teams.cdn.office.net/evergreen-assets/skype/v2/laugh/30a.png?v=4);
  animation: laugh 3.2083s steps(77) 5;
  height: 2310px;
  background-size: 30px 2310px;
}
@keyframes laugh {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-2310px);
  }
}
.static-reaction-surprised {
  background-position: 80% 50%;
}
.animated-reaction-surprised .reaction {
  background-image: url(https://statics.teams.cdn.office.net/evergreen-assets/skype/v2/surprised/30a.png?v=4);
  animation: surprised 2.8333s steps(68) 5;
  height: 2040px;
  background-size: 30px 2040px;
}
@keyframes surprised {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-2040px);
  }
}
.static-reaction-angry {
  background-position: 2.5% 0;
}
.animated-reaction-angry .reaction {
  background-image: url(https://statics.teams.cdn.office.net/evergreen-assets/skype/v2/angryV2/30a.png?v=4);
  animation: angry 4.375s steps(105) 5;
  height: 3150px;
  background-size: 30px 3150px;
}
@keyframes angry {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-3150px);
  }
}
.static-reaction-heart {
  background-position: 90% 0;
}
.animated-reaction-heart .reaction {
  background-image: url(https://statics.teams.cdn.office.net/evergreen-assets/skype/v2/heart/30a.png?v=4);
  // background-image: url("/public/images/heart.png");
  animation: heart 0.7083s steps(17) 5;
  height: 510px;
  background-size: 30px 510px;
}
@keyframes heart {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-510px);
  }
}
