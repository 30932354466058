$font-family-base: "Roboto", Helvetica, Arial, sans-serif;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/utilities";

$grid-columns: 12;
$grid-gutter-width: 32px;
$grid-column-width: 32px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1136px
);

@import "~bootstrap/scss/grid";
