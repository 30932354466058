body.dark {
  .teamsDatePicker {
    .MuiInput-formControl {
      // border-bottom-color: rgb(98, 100, 167) ;
      background-color: #333;
      > .MuiInput-input {
        color: rgb(255, 255, 255);
      }
    }
    .calender-icon {
      > svg {
        &:hover {
          fill: rgb(166, 167, 220);
        }
      }
    }
  }
  .MuiPopover-paper {
    background-color: rgb(41, 41, 41) !important;

    .MuiPickersDay-daySelected,
    .MuiPickersDay-day:hover {
      background-color: #242424;
    }
    .MuiPickersDay-daySelected {
      .MuiIconButton-label {
        color: #fff !important;
      }
    }
    .MuiPickersDay-day {
      outline: none !important;
      color: rgb(200, 198, 196);
    }
    .MuiPickersCalendarHeader-dayLabel {
      color: rgba(200, 198, 196, 0.8);
    }
    .MuiPickersCalendarHeader-switchHeader {
      color: rgb(255, 255, 255);
    }
    .MuiPickersCalendarHeader-iconButton {
      color: rgb(255, 255, 255);
      background: transparent;
      outline: none !important;

      &:hover {
        background: #242424;
      }
    }
  }
}

body.contrast {
  .teamsDatePicker {
    .MuiInput-formControl {
      background-color: rgb(0, 0, 0);
      > .MuiInput-input {
        color: rgb(255, 255, 255);
        border: 0.0625rem solid rgb(255, 255, 255);
      }
    }
    .calender-icon {
      > svg {
        &:hover {
          fill: rgb(26, 235, 255);
        }
      }
    }
  }

  .MuiPopover-paper {
    background-color: #000 !important;
    border: 1px solid #fff;

    .MuiPickersDay-daySelected {
      background-color: #00ebff !important;
    }
    .MuiPickersDay-day:hover {
      background-color: #ffff00;
    }
    .MuiPickersDay-daySelected,
    .MuiPickersDay-day:hover {
      .MuiIconButton-label {
        color: #000 !important;
      }
    }
    .MuiPickersDay-day {
      outline: none !important;
      color: #fff;
    }
    .MuiPickersCalendarHeader-dayLabel {
      color: rgba(255, 255, 255, 0.8);
    }
    .MuiPickersCalendarHeader-switchHeader {
      color: rgb(255, 255, 255);
    }
    .MuiPickersCalendarHeader-iconButton {
      color: rgb(255, 255, 255);
      background: transparent;
      outline: none !important;

      &:hover {
        background: #242424;
      }
    }
  }
}

body.default {
  .teamsDatePicker {
    .MuiInput-formControl {
      // border-bottom-color: rgb(98, 100, 167) ;
      background-color: #ebebeb;
      > .MuiInput-input {
        color: rgb(37, 36, 35);
      }
    }

    .calender-icon {
      > svg {
        &:hover {
          fill: rgb(98, 100, 167);
        }
      }
    }
  }

  .MuiPopover-paper {
    background-color: rgb(255, 255, 255) !important;

    .MuiPickersDay-daySelected,
    .MuiPickersDay-day:hover {
      background-color: #6264a7;
    }
    .MuiPickersDay-daySelected,
    .MuiPickersDay-day:hover {
      .MuiIconButton-label {
        color: #fff !important;
      }
    }
    .MuiPickersDay-day {
      outline: none !important;
      color: rgb(97, 97, 97);
    }
    .MuiPickersCalendarHeader-dayLabel {
      color: rgba(97, 97, 97, 0.8);
    }
    .MuiPickersCalendarHeader-switchHeader {
      color: rgb(36, 36, 36);
    }
    .MuiPickersCalendarHeader-iconButton {
      color: rgb(36, 36, 36);
      background: transparent;
      outline: none !important;

      &:hover {
        background: rgb(237, 235, 233);
      }
    }
  }
}

.teamsDatePicker {
  width: 167px;
  position: relative;
  .MuiInput-formControl {
    height: 30px;
    border-radius: 5px;
    &:before,
    &:after {
      content: none;
      border: none;
    }

    > .MuiInput-input {
      width: 100%;
      height: 100%;
      padding: 0 0.8571rem;
      margin-bottom: 0;
      position: relative;
      border-radius: 0.1875rem 0.1875rem 0.125rem 0.125rem;
      line-height: 1.85;
      font-size: 0.875rem;
    }
  }

  .calender-icon {
    cursor: pointer;
    position: absolute;
    right: calc(0.8571rem);
    top: calc(50% - 9px);

    > svg {
      height: 18px;
      width: 18px;
    }
  }
}
