@import "../../../styles/variables";

.groupedGrid table > thead > tr:first-of-type > th:not(:first-child) {
  text-align: center;
}

.st_good,
.st_average,
.st_poor,
.st_none {
  > svg {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

.st_good {
  color: rgb(35, 123, 75);
}

.st_average {
  color: rgb(248, 210, 42);
}

.st_poor {
  color: rgb(196, 49, 75);
}

.st_none {
  color: rgb(255, 255, 255);
}

.groupedGrid,
.standardGrid {
  .k-grid-content {
    td:not(.k-grid-content-sticky) {
      text-align: center;
    }
  }
  .k-grid-header {
    th:not(.k-grid-header-sticky) {
      text-align: center;
    }
  }
}

td.darken{
  background-color: rgba(0, 0, 0, 0.07);
}
